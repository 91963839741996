const btnArrowIcon = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      d="M11.073 2.91903L1.78959 12.2024L0.698314 11.1111L9.98169 1.82775L4.21026 1.74567L4.18861 0.22404L12.5577 0.34306L12.6767 8.71217L11.1551 8.69052L11.073 2.91903Z"
      fill="white"
    />
  </svg>
);

export default btnArrowIcon;
