import { useEffect, useRef } from 'react';
import closeIcon from '../assets/imgs/closeIcon.svg';
import Loader from './Loader/Loader';

const Popup = ({
  close,
  loading,
  children,
  title = '',
  className = '',
  windowClassName = '',
}) => {
  const windowRef = useRef(null);

  useEffect(() => {
    const html = document.querySelector('html');
    if (!html) return;
    html.style.overflowY = 'hidden';
    return () => {
      html.style.overflowY = 'scroll';
    };
  }, []);

  useEffect(() => {
    const onClick = (e) => {
      if (windowRef.current) {
        if (!windowRef.current.contains(e.target)) {
          // console.log(e.target);
          // console.log(windowRef.current);
          close();
        }
      }
    };
    setTimeout(() => {
      document.addEventListener('click', onClick);
    }, 50);
    return () => document.removeEventListener('click', onClick);
  }, [close]);

  return (
    <div
      className={'popupWrap ' + className}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={'window' + (loading ? ' loading' : '')} ref={windowRef}>
        <button onClick={close} className={'btnClose'}>
          <img src={closeIcon} alt="" />
        </button>
        {title && <h2 className={'title'}>{title}</h2>}
        <div className={'content'}>{children}</div>
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default Popup;
