const Footer = () => {
  return (
    <div className="footer">
      <p>© Fastmotion, 2024 Москва </p>
      <a href="#" className="link">
        Политика конфиденциальности
      </a>
    </div>
  );
};

export default Footer;
