const ratingStarIcon = (
  <svg width="14" height="13" fill="none" viewBox="0 0 14 13">
    <path
      d="M7.00116 10.651L2.88665 12.9541L3.80559 8.32929L0.34375 5.1279L5.02619 4.57272L7.00116 0.291016L8.9761 4.57272L13.6585 5.1279L10.1967 8.32929L11.1156 12.9541L7.00116 10.651Z"
      fill="#323339"
    />
  </svg>
);

export default ratingStarIcon;
