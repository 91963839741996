import { useEffect, useState } from 'react';
import Scale from './components/Scale';
import Header from './components/Header';
import tgIcon from './assets/imgs/tgIcon.svg';
import starIcon from './assets/imgs/starIcon.svg';
import stageRoad from './assets/imgs/stageRoad.svg';
import clockIcon from './assets/imgs/clockIcon.svg';
import btnArrowIcon from './assets/imgs/btnArrowIcon';
import stageRoadMob from './assets/imgs/stageRoadMob.svg';
import caseVideoPoster from './assets/imgs/caseVideoPoster.png';
import createChannelImg from './assets/imgs/createChannelImg.png';
import companyIcon1 from './assets/imgs/companyList/companyIcon1.svg';
import companyIcon2 from './assets/imgs/companyList/companyIcon2.svg';
import companyIcon3 from './assets/imgs/companyList/companyIcon3.svg';
import companyIcon4 from './assets/imgs/companyList/companyIcon4.svg';
import companyIcon5 from './assets/imgs/companyList/companyIcon5.svg';
import serviceIcon1 from './assets/imgs/serviceList/serviceIcon1.svg';
import serviceIcon2 from './assets/imgs/serviceList/serviceIcon2.svg';
import serviceIcon3 from './assets/imgs/serviceList/serviceIcon3.svg';

import bgGradient1 from './assets/imgs/gradients/gradient1.png';
import bgGradient2 from './assets/imgs/gradients/gradient2.png';
import bgGradient3 from './assets/imgs/gradients/gradient3.png';
import bgGradient4 from './assets/imgs/gradients/gradient4.png';
import bgGradient5 from './assets/imgs/gradients/gradient5.png';
import bgGradient1Mob from './assets/imgs/gradients/gradient1Mob.png';
import bgGradient2Mob from './assets/imgs/gradients/gradient2Mob.png';
import bgGradient5Mob from './assets/imgs/gradients/gradient5Mob.png';

import teammate1 from './assets/imgs/teammates/teammate1.png';
import teammate2 from './assets/imgs/teammates/teammate2.png';
import teammate3 from './assets/imgs/teammates/teammate3.png';
import teammate4 from './assets/imgs/teammates/teammate4.png';
import teammate5 from './assets/imgs/teammates/teammate5.png';
import teammate6 from './assets/imgs/teammates/teammate6.png';
import teammate7 from './assets/imgs/teammates/teammate7.jpg';

import descIcon1 from './assets/imgs/descList/descIcon1';
import descIcon2 from './assets/imgs/descList/descIcon2';
import descIcon3 from './assets/imgs/descList/descIcon3';
import descIcon4 from './assets/imgs/descList/descIcon4';
import descIcon5 from './assets/imgs/descList/descIcon5';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import StarsRating from './components/StarsRating';
import Footer from './components/Footer';
import Popup from './components/Popup';
import formValidator from './utils/formValidator';
import axios from 'axios';

function App() {
  const API_URL = 'https://prod.fast-motion.ru';
  const [mainCompanyList] = useState([
    { name: '', img: companyIcon1, href: '#' },
    { name: '', img: companyIcon2, href: '#' },
    { name: '', img: companyIcon3, href: '#' },
    { name: '', img: companyIcon4, href: '#' },
    { name: '', img: companyIcon5, href: '#' },
  ]);
  const [caseList] = useState([
    {
      likes: '1.1',
      views: '27',
      subscribers: '8',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Тинькофф"',
      desc: 'ЧТО ЛОМАЕТСЯ У ЭЛЕКТРОСАМОКАТОВ Segway-Ninebot? Разбор всех моделей!',
      videoSrc: 'https://www.youtube.com/embed/FzPyFWO5uxQ?si=F83DtjbMY0_cDq-Y',
    },
    {
      likes: '1',
      views: '17',
      subscribers: '6',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Автохимия Лавр"',
      desc: 'ВОССТАНАВЛИВАЕМ ЭЛЕКТРОСАМОКАТ ПОСЛЕ ПОЖАРА!',
      videoSrc: 'https://www.youtube.com/embed/PZSvUZGqCBw?si=R_vUZ-DJ6X-aMa0j',
    },
    {
      likes: '2',
      views: '91',
      subscribers: '12',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Кикшеринг Sunrent',
      desc: 'ТОП 5 ЛУЧШИХ ЭЛЕКТРОСАМОКАТОВ',
      videoSrc:
        'https://www.youtube.com/embed/b-A32WqaRcQ?si=P3sOQvyrWsPqbR9Z&t=80',
    },
    {
      likes: '1',
      views: '23',
      subscribers: '3',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Navee самокаты"',
      desc: 'СДЕЛАЙ ЭТО И ЭЛЕКТРОСАМОКАТ ПЕРЕЖИВЁТ ЗИМУ',
      videoSrc:
        'https://www.youtube.com/embed/5OT8pUc2gio?si=FZJXUpCC--GRUCao&t=50',
    },
    {
      likes: '1',
      views: '32',
      subscribers: '8',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Мастерские idamaster"',
      desc: 'ТРАМПЛИНЫ на ЭЛЕКТРОСАМОКАТЕ! KugooKirin G4pro на мотокроссовом треке',
      videoSrc:
        'https://www.youtube.com/embed/LddU3JMyuMQ?si=vKHf7O1eFaWAlK8h&t=129',
    },
    {
      likes: '2.2',
      views: '55',
      subscribers: '11',
      img: caseVideoPoster,
      name: 'Интеграция при поддержке "Кикшеринг Sunrent"',
      desc: 'САМОКАТАМ НЕ МЕСТО НА ДОРОГАХ?',
      videoSrc: 'https://www.youtube.com/embed/XO4ALZwUR2k?t=27',
    },
  ]);
  const [serviceList] = useState([
    {
      name: 'Полноформатный обзор',
      desc: 'Посвятим ролик (15-30 минут) вашему продукту или услуге. Расскажем о всех деталях и функционале, покажем в действии и увлечём зрителя динамикой.',
      img: serviceIcon1,
    },
    {
      name: 'Интеграция',
      desc: 'В наших роликах есть 2 слота для вашей интеграции. Мы снимаем игровые и обычные (разговорные) рекламные вставки, которые в полной мере расскажут о вас и вашем продукте. Подойдёт для брендов различной тематики.',
      img: serviceIcon2,
      href: '#',
    },
    {
      name: 'Shorts',
      desc: 'Мы создаём короткие ролики для YouTube, TikTok, Reels. В них мы расскажем о всех прелестях вашего продукта или услуги, визуально обозначим все важные детали и расставим акцентные точки, рассказав всё то, что важно для вашего бренда.',
      img: serviceIcon3,
      href: '#',
    },
  ]);
  const [createChannelTagList] = useState([
    { name: 'Анализ и стратегия', href: '#' },
    { name: 'Создание и оформление канала', href: '#' },
    { name: 'Разработка контент-плана', href: '#' },
    { name: 'Продвижение и реклама', href: '#' },
    { name: 'SEO-оптимизация', href: '#' },
    { name: 'Производство видеоконтента', href: '#' },
    { name: 'Аналитика и отчетность', href: '#' },
  ]);
  const [workStagesList] = useState([
    {
      name: 'Знакомство',
      desc: 'знакомим вас с командой, которая будет работать над вашим проектом',
    },
    {
      name: 'Обсуждение проекта',
      desc: 'обсуждаем цели, идеи и задачи',
    },
    {
      name: 'Создание сценария',
      desc: 'составляем контент план, пишем темы видеороликов, согласовываем, предлагаем форматы роликов',
    },
    {
      name: 'Съёмка контента',
      desc: 'подбираем локацию, снимаем видеоматериал на выбранные темы',
    },
    {
      name: 'Монтаж видео',
      desc: 'монтируем видеоролики с использованием эффектов, анимации, создаем продающее описание',
    },
    {
      name: 'Согласование видео с заказчиком',
      desc: 'вносим правки заказчика, после итогового согласования получаем остаток средств по договору',
    },
  ]);
  const [whatGetList] = useState([
    'Идеи для видео и стратегии продвижения',
    'Съемку видео нашей командой профессионалов',
    'Продажи и трафик на сайт',
    'Охваты и рост аудитории',
    'Новых клиентов для бизнеса',
    'Узнаваемость бренда и лояльность аудитории',
    'Дополнительный контент',
    'Влиятельное мнение специалиста о вашем продукте',
  ]);
  const [audienceDataList] = useState([
    {
      icon: clockIcon,
      name: 'Время просмотра подписчиками',
      stat: [
        { name: 'Без подписки', val: 93.1 },
        { name: 'С подпиской', val: 6.9 },
      ],
    },
    {
      icon: clockIcon,
      name: 'Пол',
      stat: [
        { name: 'Женщины', val: 9.6 },
        { name: 'Мужчины', val: 89.7 },
        { name: 'Определено пользователем', val: 0.7 },
      ],
    },
    {
      icon: clockIcon,
      name: 'Возраст',
      stat: [
        { name: '13-17', val: 5.1 },
        { name: '18-24', val: 41.4 },
        { name: '25-34', val: 26.7 },
        { name: '35-44', val: 17.0 },
        { name: '45-54', val: 5.8 },
      ],
    },
    {
      icon: clockIcon,
      name: 'Топ регионов',
      stat: [
        { name: 'Россия', val: 73.6 },
        { name: 'Украина', val: 5.3 },
        { name: 'Казахстан', val: 2.5 },
        { name: 'Беларусь', val: 2.1 },
        { name: 'Германия', val: 0.8 },
      ],
    },
    {
      icon: clockIcon,
      name: 'Города',
      stat: [
        { name: 'Москва', val: 11.0 },
        { name: 'Санкт-Петербург', val: 5.5 },
        { name: 'Краснодар', val: 3.1 },
        { name: 'Екатеринбург', val: 2.6 },
        { name: 'Нижний новгород', val: 2.4 },
      ],
    },
  ]);
  const [reviewsList] = useState([
    {
      rating: 5,
      name: 'Александр',
      text: 'Выражаем признательность агентству за высокое качество работ и соблюдение жестких сроков при реализации нашего проекта',
    },
    {
      rating: 5,
      name: 'Александр',
      text: 'Выражаем признательность агентству за высокое качество работ и соблюдение жестких сроков при реализации нашего проекта',
    },
    {
      rating: 5,
      name: 'Александр',
      text: 'Выражаем признательность агентству за высокое качество работ и соблюдение жестких сроков при реализации нашего проекта',
    },
    {
      rating: 5,
      name: 'Александр',
      text: 'Выражаем признательность агентству за высокое качество работ и соблюдение жестких сроков при реализации нашего проекта',
    },
  ]);
  const [teamList] = useState([
    {
      img: teammate7,
      name: 'Александр',
      status: 'Продюсер',
    },
    {
      img: teammate3,
      name: 'Адам',
      status: 'Режиссёр',
    },
    {
      img: teammate4,
      name: 'Илья',
      status: 'Монтажер',
    },
    {
      img: teammate1,
      name: 'Максим',
      status: 'Ведущий',
    },
    {
      img: teammate5,
      name: 'Лена',
      status: 'Менеджер по рекламе',
    },
    {
      img: teammate2,
      name: 'Дарья',
      status: 'Офис менеджер',
    },
    {
      img: teammate6,
      name: 'Эдуард',
      status: 'Главный мастер',
    },
  ]);
  const [tgLinks] = useState([
    {
      name: 'Александр',
      tel: '+7 (905) 588-72-72',
      href: 'https://t.me/amsterdamtoronto',
    },
    {
      name: 'Елена',
      tel: '+7 (923) 398-66-03',
      href: 'https://t.me/Wolframaiss',
    },
  ]);
  const [descList] = useState([
    {
      text: 'www.youtube.com/@FastMotionElectric',
      icon: descIcon1,
      href: 'www.youtube.com/@FastMotionElectric',
    },
    { text: '148 тыс. подписчиков', icon: descIcon2 },
    { text: '508 видео', icon: descIcon3 },
    { text: '122 005 497 просмотров', icon: descIcon4 },
    { text: 'Россия', icon: descIcon5 },
  ]);
  const [formData, setFormData] = useState({
    name: '',
    tel: '',
  });
  const [formStatus, setFormStatus] = useState();
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    tel: '',
  });
  const [isPopupForm, setIsPopupForm] = useState(false);
  const submitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormIsLoading(true);
    const errors = formValidator(formData, {
      name: ['required'],
      tel: ['required', 'tel'],
    });
    console.log(errors);
    if (errors) {
      setErrors({ ...errors });
      setFormIsLoading(false);
      return;
    }
    console.log('submitForm');
    const body = new FormData();
    body.append('url', API_URL);
    body.append('name', formData.name);
    body.append('phone', formData.tel);
    body.append('subject', 'Заказать самокат');
    // fake
    // setTimeout(() => {
    //   setFormIsLoading(false);
    //   setFormStatus({
    //     status: 0,
    //     message: 'Не удалось отправить заявку, попробуйте ещё раз',
    //     // status: 1,
    //     // message:
    //     // 'Заявка успешно отправлена, в ближайшее время с вами свяжется наш специалист',
    //   });
    // }, 1000);
    // return;
    // fake end

    try {
      // with fetch
      const res = await fetch(API_URL + '/mailer.php', {
        method: 'POST',
        body: body,
      });

      if (res.status === 200) {
        setFormStatus({
          status: 1,
          message:
            'Заявка успешно отправлена, в ближайшее время с вами свяжется наш специалист',
        });
      } else {
        throw new Error();
      }

      // with axios

      // const res = await axios.post(API_URL + '/mailer.php', {
      //   name: formData.name,
      //   phone: formData.tel,
      //   subject: 'Заказать самокат',
      //   url: 'https://fast-motion.ru:443/?back_url_admin=%2Fbitrix%2Fadmin%2F',
      // });
      console.log(res);
    } catch (e) {
      setFormStatus({
        status: 0,
        message: 'Не удалось отправить заявку, попробуйте ещё раз',
      });
      console.log(e);
    } finally {
      setFormIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isPopupForm && formStatus) {
      setFormStatus(undefined);
    }
  }, [formStatus, isPopupForm]);

  const changeFormData = (key, val) => {
    console.log(key, val);
    if (errors[key]) {
      setErrors({ ...errors, [key]: '' });
    }
    setFormData((prev) => ({ ...prev, [key]: val }));
  };
  const [phonePlaceholder, setPhonePlaceholder] = useState('(999) 999-99-99');

  const changePlaceholder = (key) => {
    switch (key) {
      case 'RU':
        return setPhonePlaceholder('999 999-99-99');
      case 'BY':
        return setPhonePlaceholder('99 999-99-99');
      case 'KZ':
        return setPhonePlaceholder('9999-99999.99');
      default:
        return setPhonePlaceholder('');
    }
  };

  return (
    <>
      {isPopupForm && (
        <Popup
          className="popupForm"
          loading={formIsLoading}
          close={() => setIsPopupForm(false)}
        >
          {!formStatus && (
            <>
              <h3 className="title">Расскажите нам о своем проекте</h3>
              <p className="desc">
                Оставьте свои данные, и мы свяжемся с вами в ближайшее время
              </p>
              <form className="form" action={submitForm}>
                <div className={'inputWrap ' + (errors.name ? 'error' : '')}>
                  <span>Ваше имя</span>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => changeFormData('name', e.target.value)}
                  />
                  <span className="errText">{errors.name}</span>
                </div>
                <div className={'inputWrap ' + (errors.tel ? 'error' : '')}>
                  {/* <span>Телефон</span> */}
                  {/* <input
                type="tel"
                value={formData.tel}
                onChange={(e) => changeFormData('tel', e.target.value)}
              /> */}
                  <PhoneInput
                    // country="RU"
                    value={formData.tel}
                    international={false}
                    defaultCountry={'RU'}
                    placeholder={phonePlaceholder}
                    countries={['RU', 'KZ', 'BY']}
                    onCountryChange={(val) => changePlaceholder(val)}
                    onChange={(val) => changeFormData('tel', val)}
                  />
                  <span className="errText">{errors.tel}</span>
                </div>
                <button onClick={submitForm} className="btn">
                  Отправить
                </button>
              </form>
              <span className="conditions">
                Нажимая кнопку "отправить", вы соглашаетесь на обработку
                персональных данных. 
              </span>
            </>
          )}
          {formStatus?.status === 0 && (
            <>
              <h3 className="title">Ошибка!</h3>
              <p className="desc">{formStatus.message}</p>
              <button
                onClick={() => {
                  setFormStatus(undefined);
                  setIsPopupForm(true);
                }}
                className="btn"
              >
                Попробовать ещё
              </button>
            </>
          )}
          {formStatus?.status === 1 && (
            <>
              <h3 className="title">Успешно!</h3>
              <p className="desc">{formStatus.message}</p>
              <button onClick={() => setIsPopupForm(false)} className="btn">
                Хорошо
              </button>
            </>
          )}
        </Popup>
      )}

      <Header />
      <main className="main">
        <h1 className="mainTitle">Поможем сделать Level Up вашему бизнесу!</h1>
        <p className="mainTitleText">
          Наши видео принесут вашему бренду узнаваемость, лояльность и интерес
          аудитории
        </p>
        <button onClick={() => setIsPopupForm(true)} className="btn btnLink">
          <span>Обсудить проект</span>
          {btnArrowIcon}
        </button>
        <ul className="mainCompanyList">
          {mainCompanyList.map((company, i) => (
            <a href={company.href} key={i}>
              <img src={company.img} alt={company.name} />
            </a>
          ))}
        </ul>
      </main>
      <hr />
      <section className="cases" id="cases">
        <h2 className="title">Наши кейсы</h2>
        <ul className="caseList">
          {caseList.map((caseItem, i) => (
            <li className="caseItem" key={i}>
              <iframe
                frameBorder="0"
                allowFullScreen
                src={caseItem.videoSrc}
                referrerPolicy="strict-origin-when-cross-origin"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <div className="caseInfo">
                <p className="caseName">{caseItem.name}</p>
                <p className="caseDesc">{caseItem.desc}</p>
                <ul className="caseDataList">
                  <li>
                    <h3>{caseItem.views} К</h3>
                    <p>Просмотров</p>
                  </li>
                  <li>
                    <h3>{caseItem.subscribers} К</h3>
                    <p>Новых подписчиков</p>
                  </li>
                  <li>
                    <h3>{caseItem.likes} К</h3>
                    <p>Нравится</p>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCvy7FIEQYztchmNfCROlgDw"
          className="btn btnLink"
        >
          <span>Показать еще</span>
          {btnArrowIcon}
        </a>
      </section>

      <section className="film">
        <div className="filmWindow">
          <div className="filmInfo">
            <p>Наш самый большой фильм</p>
            <span>
              Мы один из самых больших каналов про электротранспорт и
              micromobilty в России. Просмотры роликов достигают 200 тысяч и в
              среднем показывают результаты 35-45 тысяч.
            </span>
          </div>
          <iframe
            frameBorder="0"
            allowFullScreen
            src="https://www.youtube.com/embed/I3ImfUb85lc"
            referrerPolicy="strict-origin-when-cross-origin"
            title="Холод, штрафы, 16 часов в день | Курьеры ЗИМОЙ"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </section>

      <section className="services" id="services">
        <h2 className="title">Наши услуги</h2>
        <ul className="serviceList">
          {serviceList.map((service, i) => (
            <li key={i}>
              <img src={service.img} alt="" />
              <p>{service.name}</p>
              <span>{service.desc}</span>
            </li>
          ))}
        </ul>
      </section>

      <section className="createChannel">
        <div className="createChannelWindow">
          <div className="left">
            <h2 className="title desktop">Создадим Youtube-канал под ключ</h2>
            <img className="createChannelImg" src={createChannelImg} alt="" />
          </div>
          <div className="right">
            <h2 className="title mobile">Создадим Youtube-канал под ключ</h2>
            <p className="createChannelDesc">
              Хотите завоевать аудиторию на YouTube и превратить свой канал в
              мощный инструмент продвижения?
              <br /> Мы создадим для вас YouTube-канал под ключ, который
              привлечет внимание и поможет достичь ваших целей
            </p>
            <ul className="createChannelTagList">
              {createChannelTagList.map((tag, i) => (
                <li key={i}>{tag.name}</li>
              ))}
            </ul>
            <button
              onClick={() => setIsPopupForm(true)}
              className="btn btnLink"
            >
              <span>Оставить заявку</span>
              {btnArrowIcon}
            </button>
          </div>
        </div>
      </section>

      <section className="workStages" id="workStages">
        <h2 className="title">Этапы работы</h2>
        <ul className="workStagesList">
          {workStagesList.map((stage, i) => (
            <li key={i} className="workStagesItem">
              <div className="stageNumb">{i + 1}</div>
              <div className="workStagesItemInfo">
                <p>{stage.name}</p>
                <span>{stage.desc}</span>
              </div>
            </li>
          ))}
          <img className="stageRoadImg desktop" src={stageRoad} alt="" />
          <img className="stageRoadImg mobile" src={stageRoadMob} alt="" />
        </ul>
      </section>

      <section className="whatGet">
        <h2 className="title">Что вы получите</h2>
        <ul>
          {whatGetList.map((whatGetItem, i) => (
            <li key={i}>
              <img src={starIcon} alt="" />
              <p>{whatGetItem}</p>
            </li>
          ))}
        </ul>
      </section>

      <section className="audience">
        <div className="audienceWindow">
          <div className="left">
            <h2 className="title">Аудитория и география</h2>
            <div className="descWrap">
              <span className="subTitle">Описание</span>
              <ul className="descList">
                {descList.map((desc) => (
                  <li className="descItem">
                    {/* <img src={desc.icon} alt="" /> */}
                    {desc.icon}
                    {desc.href ? (
                      <a href={desc.href}>{desc.text}</a>
                    ) : (
                      <span>{desc.text}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <ul className="caseDataList">
              <li>
                <h3>121 M</h3>
                <p>Просмотров</p>
              </li>
              <li>
                <h3>150 К</h3>
                <p>Подписчиков</p>
              </li>
              <li>
                <h3>508</h3>
                <p>Видео</p>
              </li>
            </ul>
          </div>
          <div className="right">
            <Swiper
              navigation
              autoplay
              spaceBetween={20}
              slidesPerView={1}
              modules={[Navigation, Autoplay]}
            >
              {audienceDataList.map((item, i) => (
                <SwiperSlide key={i}>
                  <div className="audienceBlock">
                    <img className="audienceTopIcon" src={clockIcon} alt="" />
                    <h3>{item.name}</h3>
                    <ul className="audienceStat">
                      {item.stat.map((stat, i) => (
                        <Scale key={i} {...stat} />
                      ))}
                    </ul>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      {/* <section className="reviews">
        <h2 className="title">Отзывы</h2>
        <ul className="reviewsList">
          {reviewsList.map((item, i) => (
            <li className="review" key={i}>
              <StarsRating rating={item.rating} />
              <p className="reviewsText">{item.text}</p>
              <p className="reviewsSender">{item.name}</p>
            </li>
          ))}
        </ul>
      </section> */}

      <section className="team" id="team">
        <h2 className="title">Наша команда</h2>
        <ul className="teamList">
          {teamList.map((item, i) => (
            <li className="teammate" key={i}>
              <img className="teammateImg" src={item.img} alt="" />
              <p className="teammateName">{item.name}</p>
              <p className="teammateStatus">{item.status}</p>
            </li>
          ))}
        </ul>
      </section>

      <section className="contacts" id="contacts">
        <h2 className="title">Давайте обсудим ваш проект</h2>
        <div className="contactsContent">
          <p className="text">
            Вы можете связаться с нами любым удобным способом: оставить заявку,
            написать на почту, в телеграм, или позвонить нам.{' '}
          </p>
          <div className="links">
            {tgLinks.map((item, i) => (
              <a key={i} target="_blank" className="link tg" href={item.href}>
                <div className="headerContact">
                  <div className="tgBtn">
                    <img src={tgIcon} alt="tg" />
                  </div>
                  <span className="phone">{item.tel}</span>
                  <p>{item.name}</p>
                </div>
              </a>
            ))}
            <p className="desktop"></p>
            {/* <a className="link" href="tel:+79055887272">
              +7 (905) 588-72-72
            </a>
            <a className="link" href="tel:+79233986603">
              +7 (923) 398-66-03
            </a> */}
            <a className="link" href="mailto:office@fast-motion.ru">
              office@fast-motion.ru
            </a>
          </div>
          {/* <a href="#" className="tgLink">
            <img src={tgIcon} alt="tg" />
            <span>Телеграм</span>
          </a> */}

          <button onClick={() => setIsPopupForm(true)} className="btn btnLink">
            <span>Оставить заявку</span>
            {btnArrowIcon}
          </button>
        </div>
      </section>

      <hr className="footerHr" />
      <Footer />

      <img src={bgGradient1} className=" bgGradient bg1" alt="" />
      {/* <img src={bgGradient1} className="desktop bgGradient bg1" alt="" /> */}
      {/* <img src={bgGradient1Mob} className="mobile bgGradient bg1" alt="" /> */}
      <img src={bgGradient2} className="desktop bgGradient bg2" alt="" />
      <img src={bgGradient2Mob} className="mobile bgGradient bg2" alt="" />
      <img src={bgGradient3} className="bgGradient bg3" alt="" />
      <img src={bgGradient4} className="bgGradient bg4" alt="" />
      <img src={bgGradient5} className="desktop bgGradient bg5" alt="" />
      <img src={bgGradient5Mob} className="mobile bgGradient bg5" alt="" />
    </>
  );
}

export default App;
