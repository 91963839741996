const Scale = ({ name, val }) => {
  return (
    <div className={'scaleItem'}>
      <p className={'scaleName'}>{name}</p>
      <div className={'scale'}>
        <div className={'scaleFull'} style={{ width: `${val}%` }}></div>
      </div>
      <p className="scaleValue">{val} %</p>
    </div>
  );
};

export default Scale;
