const descIcon1 = (
  <svg
    enableBackground="new 0 0 24 24"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    focusable="false"
    aria-hidden="true"
  >
    <path d="M11.99 1.98C6.46 1.98 1.98 6.47 1.98 12s4.48 10.02 10.01 10.02c5.54 0 10.03-4.49 10.03-10.02S17.53 1.98 11.99 1.98zM8.86 14.5c-.16-.82-.25-1.65-.25-2.5 0-.87.09-1.72.26-2.55h6.27c.17.83.26 1.68.26 2.55 0 .85-.09 1.68-.25 2.5H8.86zm6.03 1c-.54 1.89-1.52 3.64-2.89 5.15-1.37-1.5-2.35-3.25-2.89-5.15h5.78zM9.12 8.45c.54-1.87 1.52-3.61 2.88-5.1 1.36 1.49 2.34 3.22 2.88 5.1H9.12zm7.03 1h4.5c.24.81.37 1.66.37 2.55 0 .87-.13 1.71-.36 2.5h-4.51c.15-.82.24-1.65.24-2.5 0-.87-.09-1.72-.24-2.55zm4.14-1h-4.38c-.53-1.97-1.47-3.81-2.83-5.4 3.25.4 5.96 2.51 7.21 5.4zm-9.37-5.4c-1.35 1.59-2.3 3.43-2.83 5.4H3.71c1.24-2.9 3.96-5.01 7.21-5.4zm-7.57 6.4h4.5c-.15.83-.24 1.68-.24 2.55 0 .85.09 1.68.24 2.5H3.34c-.23-.79-.36-1.63-.36-2.5 0-.89.13-1.74.37-2.55zm.34 6.05h4.39c.52 1.99 1.48 3.85 2.84 5.45-3.27-.39-6-2.53-7.23-5.45zm9.4 5.45c1.36-1.6 2.32-3.46 2.84-5.45h4.39c-1.24 2.92-3.97 5.05-7.23 5.45z"></path>
  </svg>
);

export default descIcon1;
