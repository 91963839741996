import { useState } from 'react';
import logoIcon from '../assets/imgs/logo.svg';
import tgIcon from '../assets/imgs/tgIcon.svg';
import tgIcon2 from '../assets/imgs/tgIcon2.svg';
import closeIcon from '../assets/imgs/closeIcon.svg';
import burgerIcon from '../assets/imgs/burgerIcon.svg';

const Header = () => {
  const [navItems] = useState([
    { name: 'Кейсы', href: '#cases' },
    { name: 'Услуги', href: '#services' },
    { name: 'Этапы работы', href: '#workStages' },
    { name: 'Команда', href: '#team' },
    { name: 'Контакты', href: '#contacts' },
  ]);
  const [mobMenuOpened, setMobMenuOpened] = useState(false);
  const [tgLinks] = useState([
    {
      name: 'Александр',
      tel: '+7 (905) 588-72-72',
      href: 'https://t.me/amsterdamtoronto',
    },
    {
      name: 'Елена',
      tel: '+7 (923) 398-66-03',
      href: 'https://t.me/Wolframaiss',
    },
  ]);

  return (
    <div className="header">
      <div className="logoWrap">
        <img src={logoIcon} alt="logo" />
        <p>Продакшн</p>
      </div>
      <ul className="headerNav">
        {navItems.map((navItem, i) => (
          <a key={i} className="link" href={navItem.href}>
            {navItem.name}
          </a>
        ))}
      </ul>
      <div className="headerContacts">
        {tgLinks.map((item, i) => (
          <a key={i} target="_blank" className="link tg" href={item.href}>
            <div className="headerContact">
              <div className="tgBtn">
                <img src={tgIcon} alt="tg" />
              </div>
              <span className="phone">{item.tel}</span>
              <p>{item.name}</p>
            </div>
          </a>
        ))}
      </div>
      {/* <a className="link tg" href="https://t.me/amsterdamtoronto">
          <div className="headerContact">
            <a className="link tg" href="#">
            <div className="tgBtn">
              <img src={tgIcon} alt="tg" />
            </div>
            </a>
            <a className="link phone" href="tel:+79055887272">
            <span className="phone">+7 (905) 588-72-72</span>
            </a>
            <p>Александр</p>
          </div>
        </a> */}
      <button
        onClick={() => setMobMenuOpened((prev) => !prev)}
        className="mobile btnMenu"
      >
        <img src={burgerIcon} alt="" />
      </button>
      {mobMenuOpened && (
        <div className="mobile mobMenu">
          <div className="menuHeader">
            <div className="logoWrap">
              <img src={logoIcon} alt="logo" />
              <p>Продакшн</p>
            </div>
            <button
              onClick={() => setMobMenuOpened((prev) => !prev)}
              className="mobile btnMenu"
            >
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <ul>
            {navItems.map((navItem, i) => (
              <a
                key={i}
                className="link"
                href={navItem.href}
                onClick={() => setMobMenuOpened(false)}
              >
                {navItem.name}
              </a>
            ))}
          </ul>
          {/* <a className="link tel" href="tel:+79999999999">
            +7 (999) 999-99-99
          </a>
          <a href="#" className="tgLink">
            <img src={tgIcon2} alt="tg" />
            <span>Телеграм</span>
          </a> */}
          {tgLinks.map((item, i) => (
            <a key={i} target="_blank" className="link tg" href={item.href}>
              <div className="headerContact">
                <div className="tgBtn">
                  <img src={tgIcon} alt="tg" />
                </div>
                <span className="phone">{item.tel}</span>
                <p>{item.name}</p>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
